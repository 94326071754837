<template>
  <v-container>
    <ModalPayment
      @reloadGrid="reloadGrid"
      @seeReceiptPayment="seeReceiptPayment"
      :dialog="dialog"
      :barCode="barCode"
      @closeModal="closeModal"
    />
    <v-row justify="center">
      <v-col md="12" cols="10" class="pb-2 pl-md-8">
        <v-row class="payment">
          <span class="title-payment">Novo Pagamento</span>
        </v-row>
      </v-col>
      <v-col md="12" class="pl-md-5 pt-0 pb-0" v-if="$vuetify.breakpoint.mdAndUp">
        <v-divider></v-divider>
      </v-col>
      <v-col md="12" cols="12" class="pt-0">
        <v-row class="text-barcode">
          <v-col md="9" cols="10" class="pl-0 pb-8 pb-md-0 pa-md-5">
            <v-text-field
              v-model.lazy="barCodeInput"
              class="textField"
              v-mask="'#####.##### #####.###### #####.###### # ##############'"
            >
            </v-text-field>
            <v-textarea
              id="textarea"
              @keypress="isNumber($event)"
              v-model.lazy="barCodeInput"
              type="number"
              auto-grow
              label="Insira o código de barras"
              placeholder="00000.00000 00000.000000 00000.000000 0 00000000000000"
              rows="1"
              row-height="15"
              maxlength="59"
            ></v-textarea>
          </v-col>
          <v-col md="3" cols="12" class="button-continue pt-md-6 pa-md-5">
            <v-btn
              class="button"
              :disabled="!hasBarcode"
              :dark="hasBarcode"
              :ligth="!hasBarcode"
              @click="showModal"
              color="#D70472"
              rounded
              >Continuar</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-row>
        <v-col>
          <v-row class="table-text">
            <v-col md="4" class="pb-0 pl-8">
              <span class="table-title">Histórico de Pagamentos</span>
            </v-col>
          </v-row>
          <v-col md="12" v-if="$vuetify.breakpoint.mdAndUp">
            <v-divider></v-divider>
          </v-col>
          <v-col class="tableImport" cols="12">
            <v-data-table
              hide-default-footer
              :headers="headers"
              :items="dataTableItems"
              :mobile-breakpoint="600"
              class="dataTableContent"
            >
              <template v-slot:item.status="{ item }">
                <span class="statusColor">
                  {{ item.status }}
                </span>
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn
                  class="see-button"
                  color="#D70472"
                  @click="openPayment(item.id)"
                  width="20"
                  dark
                  outlined
                >
                  Ver
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-col>
      </v-row>
    </v-row>
    <v-snackbar v-model="getErrorPasswordToEmail" :timeout="timeout" top color="red">
      {{ getErrorPasswordToEmail }}

      <v-btn color="white" text @click="clearErrorEmail(null)">
        fechar
      </v-btn>
    </v-snackbar>
    <ModalReceipt @closeModal="closeModalReceipt" :dialog="dialogReceipt" />
  </v-container>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { mask } from "vue-the-mask";
import ModalPayment from "./ModalPayment.vue";
import service from "../../../../service/index";
import ModalReceipt from "./modalReceipt.vue";

export default {
  components: {
    ModalPayment,
    ModalReceipt
  },
  directives: {
    mask
  },
  data() {
    return {
      bola: false,
      dialogReceipt: false,
      service,
      timeout: 5000,
      dialog: false,
      barCode: "",
      value: "",
      headers: [
        {
          text: "Data do pagamento",
          align: "center",
          filterable: false,
          value: "date"
        },
        {
          text: "Tipos de operação",
          align: "center",
          filterable: false,
          value: "type"
        },
        {
          text: "Identificação do comprovante",
          align: "center",
          filterable: false,
          value: "description"
        },
        {
          text: "Valor (R$)",
          align: "center",
          filterable: false,
          value: "value"
        },
        {
          text: "Status",
          align: "center",
          filterable: false,
          value: "status"
        },
        {
          text: "Ação",
          align: "center",
          filterable: false,
          value: "action"
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      getErrorPasswordToEmail: "DigitalAccount/getEmailPasswordError",
      getStatement: "DigitalAccount/getConsultStatement",
      error: "DigitalAccount/getError"
    }),
    dataTableItems() {
      return this.getStatement
        .map(el => ({
          date: this.$moment(el.entryDate).format("DD/MM/YYYY"),
          type: el.type === "D" ? "Débito" : "Crédito",
          description: el.comment,
          value: this.service.formatCurrency(el.amount),
          status: "Pago",
          id: el.transactionId
        }))
        .reverse();
    },
    hasBarcode() {
      return this.barCode.length >= 47;
    },
    barCodeInput: {
      get() {
        return this.service.billetsFormat(this.barCode);
      },
      set(value) {
        this.barCode = this.service.billetsFormat(value);
      }
    }
  },
  methods: {
    ...mapMutations({
      clearErrorEmail: "DigitalAccount/SET_SEND_EMAIL_PASSWORD_ERROR"
    }),
    ...mapActions({
      setConsultStatement: "DigitalAccount/consultStatement",
      validateBarCode: "DigitalAccount/validateBarCode",
      setReceipt: "DigitalAccount/receiptTransfer"
    }),
    isNumber(evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    closeModal() {
      this.dialog = false;
    },
    closeModalReceipt() {
      this.dialogReceipt = false;
    },
    async seeReceiptPayment(payload) {
      await this.setReceipt(payload);
      this.dialogReceipt = true;
    },
    async openPayment(payload) {
      await this.setReceipt(payload);
      this.dialogReceipt = true;
    },
    async showModal() {
      if (this.barCode) {
        await this.validateBarCode(this.barCode.replace(/[ ,.]/g, ""));
        if (!this.error) this.dialog = true;
      }
    },
    async reloadGrid() {
      const start = new Date(
        this.$moment()
          .subtract(30, "days")
          .format()
      );
      const end = new Date(this.$moment().format());
      await this.setConsultStatement({
        period: {
          startDate: start.toISOString(),
          endDate: end.toISOString()
        },
        direction: "output",
        transaction: "payment"
      });
    }
  },
  async created() {
    const start = new Date(
      this.$moment()
        .subtract(30, "days")
        .format()
    );
    const end = new Date(this.$moment().format());
    await this.setConsultStatement({
      period: {
        startDate: start.toISOString(),
        endDate: end.toISOString()
      },
      direction: "output",
      transaction: "payment"
    });
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/styles/digitalAccount/payments.scss";
.row {
  margin: 0 -12px !important;
}
</style>
